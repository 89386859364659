<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-02-27 15:54:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-25 09:50:02
-->
<template>
  <el-table highlight-current-row ref='table' :data="planList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column prop="planDate" label="计划日期" width="170px"></el-table-column>
    <el-table-column prop="orderNumber" label="订单号" width="120px"></el-table-column>
    <el-table-column :prop="type==2?'tardName':'stationName'" :label="type==2?'贸易企业名称':'购液企业名称'" width="120px"></el-table-column>
    <el-table-column prop="distributionType" label="配送方式" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.distributionType==1?"自提":scope.row.distributionType==2?"配送":""}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="tractorNumber" label="车辆信息" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.tractorNumber?scope.row.tractorNumber.slice(0,-5)+'/'+scope.row.trailerNumber.slice(0,-5):"--"}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="address" label="配送地" width="120px">
      <template slot-scope="scope">
        {{ scope.row.distributionType==1?scope.row.stationName:scope.row.address }}
       </template>
    </el-table-column>
    <el-table-column prop="driverInfo" label="司机信息" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.driverInfo?scope.row.driverInfo.slice(0,-5):"--"}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="escortInfo" label="押送员信息" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.escortInfo?scope.row.escortInfo.slice(0,-5):"--"}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="arrivalDate" label="期望到站时间" width="120px"></el-table-column>
    <el-table-column prop="lngSourceName" label="液源" width="120px"></el-table-column>
    <el-table-column prop="listingPrice" label="挂牌价(元/吨)" width="120px"></el-table-column>
    <el-table-column prop="weight" label="预估总重量(吨)" width="120px"></el-table-column>
    <el-table-column prop="aggregateAmount" label="预估总金额(元)" width="120px"></el-table-column>
    <el-table-column prop="auditDate" label="审批通过时间" width="120px"></el-table-column>
    <el-table-column v-if="orderStatus==2||orderStatus==3" prop="orderBill.shipmentTime" label="进厂装液时间" width="120px"></el-table-column>
    <el-table-column v-if="orderStatus==2||orderStatus==3"  prop="price" label="进厂装液价(元/吨)" width="120px"></el-table-column>
    <el-table-column v-if="orderStatus==2||orderStatus==3" prop="orderBill.shipmentWeight" label="装车量(吨)" width="120px">
      <template slot-scope="scope">
        <el-button type="text" @click="lookImage(scope.row.orderBill.shipmentImg)" >{{ scope.row.orderBill.shipmentWeight }}</el-button>
      </template>
    </el-table-column>
    <el-table-column v-if="orderStatus==3" prop="orderBill.unloadWeight" label="卸车量(吨)" width="120px">
      <template slot-scope="scope">
        <el-button type="text" @click="lookImage(scope.row.orderBill.unloadImg)" >{{ scope.row.orderBill.unloadWeight }}</el-button>
      </template>
    </el-table-column>
    <el-table-column v-if="orderStatus==3" prop="orderBill.weight" label="结算量(吨)" width="120px"></el-table-column>
    <el-table-column v-if="orderStatus==3" prop="orderBill.totalAmount" label="结算总金额(元)" width="120px"></el-table-column>
    <el-table-column v-if="orderStatus==3" prop="orderBill.reconciliationStatus" label="订单状态" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.orderBill.reconciliationStatus==1?"未对账":scope.row.orderBill.reconciliationStatus==2?"已对账":""}}</span>
      </template>
    </el-table-column>


    <el-table-column label="操作" width="200px" fixed="right">
      <template slot-scope="scope">
        <order-update v-if="orderStatus==1&&scope.row.distributionType==1&&type!=3" :role-id="scope.row.orderId" :bill-id="scope.row.orderBill.billId" 
            :stationId = "scope.row.stationId"
            @plan-list="updateRoleList" 
            :tractor-list="tractorList"
            :trailer-list="trailerList"
            :driver-list="driverList"
            :escort-list="escortList"
            :scope = "scope.row"
            />
        <order-upload v-if="orderStatus==2&&type!=3&&scope.row.orderBill.reconciliationStatus!=2" :scope = 'scope.row' :role-id="scope.row.orderId" :bill-id="scope.row.orderBill.billId" :order-status="orderStatus" @plan-list="updateRoleList"/>
        <order-upload v-if="orderStatus==3&&type!=3&&scope.row.orderBill.reconciliationStatus!=2" :scope = 'scope.row' :role-id="scope.row.orderId" :bill-id="scope.row.orderBill.billId" :order-status="orderStatus" @plan-list="updateRoleList"/>
        <!-- <order-upload-one v-if="type==3" :role-id="scope.row.orderId" :bill-id="scope.row.orderBill.billId" :order-status="orderStatus" @role-list="updateRoleList"/> -->
        <order-update-one v-if="type==3&&scope.row.orderBill.reconciliationStatus==1" :scope="scope.row" :role-id="scope.row.orderId" :bill-id="scope.row.orderBill.billId" :order-status="orderStatus" @plan-list="updateRoleList"/>
        <div v-if="type==3&&scope.row.orderBill.reconciliationStatus==2">--</div>
        <div v-if="type==2&&scope.row.orderBill.reconciliationStatus==2">--</div>
        <div v-if="type==2&&orderStatus==1&&scope.row.distributionType==2">--</div>
      </template>
    </el-table-column>
    <el-dialog
        title="图片预览"
        :visible.sync="previewDialogVisible"
        width="50%" append-to-body>
        <template v-if="previewDialogVisibleText == false">
          <img v-for="item in urlList"  :src="item" class="previewImg" @click="BlowUpImage(item)">
        </template>
      <span v-if="previewDialogVisibleText">暂无图片</span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibleOne" width="30%" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </el-table>
  
</template>

<script>
import OrderUpdate from './OrderUpdate';
import OrderUpload from './OrderUpload.vue';
import OrderUpdateOne from './OrderUpdateOne.vue';
import { getTractorRequest,getTrailerRequest,getDriverRequest,getEscortRequest } from "network/order"


  export default {
    name: "PlanTable",
    props: {
      planList: {
        type: Array,
        default() {
          return []
        }
      },
      orderStatus: {
        type: String,
        default(){
          return []
        }
      }
    },
    data(){
      return {
        tractorList:[],
        trailerList: [],
        driverList: [],
        escortList: [],
        type: '',
        previewImgUrl: '',
        previewDialogVisible: false,
        previewDialogVisibleText: false,
        urlList: [],
        dialogVisibleOne: false,
        dialogImageUrl:''
      }
    },
    components: {
        OrderUpdate,
        OrderUpload,
        OrderUpdateOne
      
    },
    created() {
      this.getTractor()
      this.getTrailer()
      this.getDriver()
      this.getEscort()
      this.type = localStorage.getItem('type')
    },
    watch: {
      planList(val){
        this.doLayout()
      }
    },
    methods: {
      doLayout(){
        let that = this
          this.$nextTick(() => {
            that.$refs.table.doLayout()
          })
      },
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('plan-list');
      },
      BlowUpImage(url){
        this.dialogImageUrl=url
        this.dialogVisibleOne = true
      },
      lookImage(url){
        
        this.previewDialogVisible = true;
        if(url==''||url==null){
         this.previewDialogVisibleText = true
        }
        else{
          this.previewDialogVisibleText = false
          let arr = url.split(',')
          this.urlList = arr.map((item)=>this.api.imageUrl+ '/trade/images/'+item)
        }
    },
      getTractor(){
      getTractorRequest({
        enterpriseId: localStorage.getItem('enterpriseId')
      }).then(res=>{
        let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取牵引车数据失败', 'error');
          }
          
          this.tractorList = result.data;
      })
    },
    getTrailer(){
      getTrailerRequest({
        enterpriseId: localStorage.getItem('enterpriseId')
      }).then(res=>{
        let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取挂车数据失败', 'error');
          }
          this.trailerList = result.data;
      })
    },
    getDriver(){
      getDriverRequest({
        enterpriseId: localStorage.getItem('enterpriseId')
      }).then(res=>{
        let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取司机数据失败', 'error');
          }
          this.driverList = result.data;
      })
    },
    getEscort(){
      getEscortRequest({
        enterpriseId: localStorage.getItem('enterpriseId')
      }).then(res=>{
        let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取押运员数据失败', 'error');
          }
          this.escortList = result.data;
      })
    },
    }
  }
</script>

<style scoped>
.previewImg{
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  margin-right: 10px;
}

</style>
