<template>
    <div style="height: 100%">
        <!-- 卡片视图 -->
        <el-card style="height: 100%;overflow:auto;">
            <!-- 顶部面包屑导航 -->
            <breadcrumb-nav>
                <template v-slot:firstMenu>订单管理</template>
                <template v-slot:secondMenu>订单列表</template>
            </breadcrumb-nav>
            <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
                订单列表
            </div>
            <div class="radioBox" v-if="type!=3">
                <el-radio-group v-model="radio1" @change="agreeChange" fill = "#fff">
                    <el-radio-button label="1" :class="radio1==1?'activeRadioButtonBox':'radioButtonBox'">待装液</el-radio-button>
                    <el-radio-button label="2" :class="radio1==2?'activeRadioButtonBox':'radioButtonBox'">运输中</el-radio-button>
                    <el-radio-button label="3" :class="radio1==3?'activeRadioButtonBox':'radioButtonBox'">已完成</el-radio-button>
                </el-radio-group>
            </div>
            <div class="radioBox" v-if="type==3">
                <el-radio-group v-model="radio1" @change="agreeChange" fill = "#fff">
                    <el-radio-button label="1" :class="radio1==1?'activeRadioButtonBox':'radioButtonBox'">待装液</el-radio-button>
                    <el-radio-button label="2" :class="radio1==2?'activeRadioButtonBox':'radioButtonBox'">已装液</el-radio-button>
                </el-radio-group>
            </div>
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="选择计划日期">
                    <el-date-picker
                            v-model="formInline.user"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            @change="onSubmit"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div class="buttonBox">
                <el-button type="primary" @click="ArrangePlan()">导出</el-button>
            </div>
            <!-- table表格区域 -->
            <order-table :plan-list="orderList" :order-status='radio1' @plan-list='updateRoleList'/>
            <div style="float: right;">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="queryInfo.pageNum"
                        :page-sizes="[10, 20, 30, 50]"
                        :page-size="queryInfo.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
  import BreadcrumbNav from "../common/BreadcrumbNav";
  import { getOrderListRequest,getOrderListOneRequest,ExportExcelRequest } from "network/order";
  import OrderTable from './orderChildComponents/OrderTable.vue';


export default {
    name: "List",
    components: {
        BreadcrumbNav,
        OrderTable,
    },
    data() {
        return {
            radio1: '1',
            type: '',
            queryInfo: {
                query: "",
                pageNum: 1,
                pageSize: 10
            },
            queryTime: {
                startDate: null,
                endDate: null
            },
            formInline: {
                user: '',
                region: ''
            },
            orderList: [],
            total: 0,
        }
    },
    created() {
        this.type = localStorage.getItem('type')
        if (localStorage.getItem('type') == 3) {
            this.getOrderListOne()
        } else {
            this.getOrderList();
        }

    },

    methods: {
        onSubmit() {
            if (this.formInline.user) {
                this.queryTime.startDate = this.formInline.user[0]
                this.queryTime.endDate = this.formInline.user[1]
            } else {
                this.queryTime = {}
            }
            if (localStorage.getItem('type') == 3) {
                this.getOrderListOne()
            } else {
                this.getOrderList();
            }
        },
        getOrderList() {
            let startDate = null
            let endDate = null
            if (this.formInline.user) {
                startDate = this.formInline.user[0]
                endDate = this.formInline.user[1]
            }
            getOrderListRequest({
                ...this.queryInfo,
                orderStatus: this.radio1,
                startDate: startDate,
                endDate: endDate,
                ...this.queryTime
            }).then(res => {
                let result = res.data;
                if (result.code !== 200) {
                    return this.alertMessage(result.msg, 'error');
                }
                this.orderList = result.data.list;
                this.total = result.data.total;
            });
        },
        getOrderListOne() {
            getOrderListOneRequest({
                ...this.queryInfo,
                orderStatus: this.radio1,
                ...this.queryTime
            }).then(res => {
                let result = res.data;
                if (result.code !== 200) {
                    return this.alertMessage('获取订单数据失败', 'error');
                }
                this.orderList = result.data.list;
                this.total = result.data.total;
            })
        },

      ArrangePlan(){
        ExportExcelRequest({
          orderStatus: this.radio1,
        }).then(res=>{
          console.log(res);
          if(res.data.code&&res.data.code !==200){
            return this.alertMessage(res.data.msg, 'error');
          }
          var content = res.headers['content-disposition'];
            var name = content && content.split(';')[1].split('filename=')[1];
            var fileName = decodeURIComponent(name)
            this.downloadCallback(res, fileName);
        })
      },

        //生成下载文件
    downloadCallback(res, fileName){
      const content = res.data;
      const blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = newSize;
        if(localStorage.getItem('type')==3){
        this.getOrderListOne()
      }else{
        this.getOrderList();
      }
      },

        // 当前页码发生变化
        handleCurrentChange(newPage) {
            this.queryInfo.pageNum = newPage;
            if (localStorage.getItem('type') == 3) {
                this.getOrderListOne()
            } else {
                this.getOrderList();
            }
        },

        updateRoleList() {
            if (localStorage.getItem('type') == 3) {
                this.getOrderListOne()
            } else {
                this.getOrderList();
            }
        },
        agreeChange(val) {
            if (localStorage.getItem('type') == 3) {
              this.queryInfo.pageNum = 1
              this.queryInfo.pageSize = 10
              this.getOrderListOne()
            } else {
                this.queryInfo.pageNum = 1
                this.queryInfo.pageSize = 10
                this.getOrderList();
            }
        },
    }
}
</script>

<style scoped>

.radioBox {
    margin-top: 30px;
    margin-bottom: 15px;
}

.buttonBox {
    display: flex;
}

.el-button {
    background-color: var(--theme_bg_color);
    border: 1px solid var(--theme_bg_color)
}

.radioButtonBox{
  background-color: var(--theme_bg_color);
  box-shadow: 0px 0 0 0 var(--theme_bg_color) !important;
  border-radius: 4px 4px 4px 4px;
}
.el-radio-button :hover{
  color: var(--theme_bg_color);
}
.activeRadioButtonBox :hover{
  color: #fff;
  box-shadow: 0px 0 0 0 var(--theme_bg_color) !important;
}
.activeRadioButtonBox.el-radio-button__orig-radio.el-radio-button__inner{
  box-shadow: 0px !important;
}
.buttonBox{
  display: flex;
}

.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
</style>
